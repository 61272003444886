<template>
  <section class="inbox--wrapper">
    <section-loader :show="loading" />
    <div class="notif--title">
      {{ $t('home.sidebarMenu.inbox') }}
    </div>
    <inbox-item v-for="inbox in inboxList" :key="inbox.msgThreadUuid" :inbox="inbox" />
    <pagination
      :push-state="false"
      :meta="metaPagination"
      @changePage="getMessages"
      :maxVisibleButtons="5"
    />
  </section>
</template>

<script>
const InboxItem = () => import('@/components/inbox/partials/inbox-item');
import { mapState } from 'vuex';
const Pagination = () => import('@/components/utils/fractal-pagination');
const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  components: {
    InboxItem,
    Pagination,
    SectionLoader,
  },
  computed: {
    ...mapState({
      inboxList: state => state.v2.myinbox.messageThreadList,
      metaPagination: state => state.v2.myinbox.metaPagination,
      loading: state => state.v2.myinbox.loading,
    }),
  },
  methods: {
    async getMessages(page) {
      await this.$store.dispatch('v2/myinbox/getAllThreads', page);
    },
  },
};
</script>
